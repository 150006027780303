import { Avatar, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";
import { CountryItem } from "utils/interfaces";
import { humanizeDate, reversedIso3, truncate } from "utils/utils";
import UserStatus from "./UserStatus";

interface IProps {
  userId: number;
  name?: string;
  country?: CountryItem;
  phone?: string;
  profile?: string;
  isActive?: number;
  onClickDetails?: (userId: number) => void;
  isUserValidatedKyc?: null | boolean;
  askedAccountDeletion?: boolean;
  registrationDate?: string;
}

const BottomContentItem: FC<IProps> = ({
  userId,
  name,
  phone,
  profile,
  country,
  isUserValidatedKyc,
  askedAccountDeletion,
  onClickDetails,
  registrationDate,
}) => {
  const status = 1;
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // gap: "10px",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "32%",
        }}
      >
        <Avatar src={profile} />

        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
            marginBottom: 5,
            marginLeft: 15,
          }}
        >
          {name}
        </p>
      </section>
      <div
        style={{ width: "23%", display: "flex", alignItems: "center", gap: 4 }}
      >
        {/* <img src={Benin} style={{ height: 20, marginTop: 4 }} /> */}
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.black,
          }}
        >
          {country ? (
            <div>
              <img
                src={`https://flagcdn.com/h20/${reversedIso3[
                  country.code
                ]?.toLowerCase()}.png`}
                width="30"
                height={25}
                alt={country.code}
              />
              <div style={{ marginLeft: 10, flex: 1, display: "flex" }}>
                <Typography style={{ textTransform: "none" }}>
                  {`${truncate(country.name, 30)} (${
                    country?.currency?.code ?? t("input.undefined")
                  })`}
                </Typography>
              </div>
            </div>
          ) : (
            t("input.undefined")
          )}
        </p>
      </div>
      <div style={{ width: "15%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.black,
          }}
        >
          {phone}
        </p>
      </div>
      <div style={{ width: "20%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {registrationDate && humanizeDate(registrationDate)}
        </p>
      </div>
      <UserStatus
        userId={userId}
        askedAccountDeletion={askedAccountDeletion}
        isUserValidatedKyc={isUserValidatedKyc}
        onClickDetails={onClickDetails}
      />
    </div>
  );
};

export default BottomContentItem;
